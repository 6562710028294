.Home h2 {
    color: white;
    line-height: 0.7;
}

.Home h3 {
    color: white;
    line-height: 0.7;
}

.Home h1 {
    line-height: 0.7;
    color: white;
    font-size: 50px;
    font-weight: bolder;
}

.Home{
    height: 100vh;
}

.intro {
    text-align: center;
    margin: 20% auto;
}

.Profile-Pic {
    border-radius: 50%;
    max-width: 250px;
    max-height: 200px;
    border: 3px solid white;
    margin-bottom: 20px;
}

.Home a {
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .Home h3 {
        line-height: 1.0;
    }
    
    .Home h1 {
        line-height: 0.9;
    }

    .intro {
        margin: 40% auto;
    }
    
  }
  
@media only screen and (max-width: 280px) {
    .Home h1 {
       font-size: 40px;
    }

}