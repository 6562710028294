.NotFound {
    background-color: white;
    padding: 20px;
    margin-top: 5vh;
    text-align: center;
}

.NotFound h1 {
    color: #000;
}

.NotFoundPic {
    max-width: 100%;
}