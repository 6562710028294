.About {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin-top: 5vh;
}

.About h1, h2, h3 {
    color: #000;
}
