@import '~antd/dist/antd.css';

body {
  background-image: url('../../assets/images/coding-bg.jpeg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: #000;
  background-size: cover;
}

.Menu {
  text-align: center;
}