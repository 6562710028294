.Contact {
    margin-top: 5vh;
    text-align: center;
    background-color: white;
    padding: 20px;
}

.Contact h2 {
    color: #000;
    margin: 10px 10px;
}