.Footer {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.Layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.LayoutContent {
  flex: 1;
  background: rgba(0, 0, 0, 0.7);
}